import anime from '../../libs/anime'

class Bandeau {
    constructor() {
        let cache = {};
        let initFunc = () => {
            initCache();
            bandeau();
        }

        let initCache = () => {
            cache.bandeau = document.querySelectorAll('.bandeau');
        }

        let bandeau = () => {
            for (let i = 0; i < cache.bandeau.length; i++) {
                let bandeau = cache.bandeau[i];
                anime({
                    targets: bandeau,
                    translateX: ['0%', '-100%'],
                    duration: 20000,
                    easing: 'linear',
                    loop: true,
                });
            }
        }
        initFunc();
    }
}

export {Bandeau}
import anime from '../libs/anime'
import Hammer from '../libs/hammer';

class Slider {
    constructor() {
        let cache = {};
        const self = this;
        let index = 0;
        let nbSlides;
        let initFunc = () => {
            initCache();
            registerEvents();
        }

        let initCache = () => {
            cache.slider = document.querySelector(".section2-slider_slides");
            cache.slides = cache.slider.querySelectorAll(".slide");
            cache.arrowLeft = cache.slider.querySelector(".arrow_left");
            cache.arrowRight = cache.slider.querySelector(".arrow_right");

            nbSlides = cache.slides.length - 1;
        };

        let registerEvents = () => {
            cache.arrowLeft.addEventListener('click', slideLeft);
            cache.arrowRight.addEventListener('click', slideRight);

            const hammertime = new Hammer(cache.slider);
            hammertime.on('swipe', function (ev) {
                if (ev.direction == 4) {
                    slideLeft();
                }
                if (ev.direction == 2) {
                    slideRight();
                }
            });
        };

        const slideLeft = function () {
            cache.arrowLeft.removeEventListener('click', slideLeft);
            const activeSlide = cache.slides[index];
            let targetSlide, prevSlide, nxtSlide;

            if (index == 0) {
                targetSlide = cache.slides[nbSlides];
                nxtSlide = cache.slides[nbSlides - 1];
            } else {
                targetSlide = cache.slides[index - 1];
                nxtSlide = index - 2 >= 0 ? cache.slides[index - 2] : cache.slides[nbSlides + index - 2];
            }
            prevSlide = index < nbSlides ? cache.slides[index + 1] : cache.slides[0];

            anime({
                targets: prevSlide,
                translateX: ['65%', '160%'],
                duration: 600,
                easing: 'easeInQuad',
            });
            anime({
                targets: activeSlide,
                translateX: ['-50%', '65%'],
                duration: 600,
                delay: 100,
                easing: 'easeInQuad',
            });
            anime({
                targets: targetSlide,
                translateX: ['-165%', '-50%'],
                duration: 600,
                delay: 200,
                easing: 'easeInQuad',
            });
            anime({
                targets: nxtSlide,
                translateX: ['-260%', '-165%'],
                duration: 600,
                delay: 300,
                easing: 'easeOutQuad',
                complete: function () {
                    cache.arrowLeft.addEventListener('click', slideLeft);
                }
            });

            index = index == 0 ? nbSlides : index - 1;
        };


        const slideRight = function () {
            cache.arrowRight.removeEventListener('click', slideRight);
            const activeSlide = cache.slides[index];
            let targetSlide, prevSlide, nxtSlide;

            if (index + 1 > nbSlides) {
                targetSlide = cache.slides[0];
                nxtSlide = cache.slides[1];
            } else {
                targetSlide = cache.slides[index + 1];
                nxtSlide = index + 2 > nbSlides ? cache.slides[0] : cache.slides[index + 2];
            }
            prevSlide = index > 0 ? cache.slides[index - 1] : cache.slides[nbSlides];

            anime({
                targets: prevSlide,
                translateX: ['-165%', '-260%'],
                duration: 600,
                easing: 'easeInQuad',
            });
            anime({
                targets: activeSlide,
                translateX: ['-50%', '-165%'],
                duration: 600,
                delay: 100,
                easing: 'easeInQuad',
            });
            anime({
                targets: targetSlide,
                translateX: ['65%', '-50%'],
                duration: 600,
                delay: 200,
                easing: 'easeInQuad',
            });
            anime({
                targets: nxtSlide,
                translateX: ['160%', '65%'],
                duration: 600,
                delay: 300,
                easing: 'easeOutQuad',
                complete: function () {
                    cache.arrowRight.addEventListener('click', slideRight);
                }
            });

            index = index >= nbSlides ? 0 : index + 1;
        };

        initFunc();
    }
}

export {Slider}
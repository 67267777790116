import { Slider } from './components/slider'
import { Bandeau } from './utils/helpers/bandeau'

class App {
  constructor() {
    if(document.querySelector(".section2-slider")) {
      const slider = new Slider();
    }
    if(document.querySelector(".section5-follow-us")) {
      const bandeau = new Bandeau();
    }
  }
}

window.onload = function() {
  let app = new App();
};
